import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';

const app = firebase.initializeApp({
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID
});

firebase
	.appCheck()
	.activate(
		new firebase.appCheck.ReCaptchaEnterpriseProvider(
			process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY
		),
		true
	);

export default app;
